import React from 'react';

const Home = () => {
    return (
        <div className="homepage">
            <header>
                <h1>Welcome to Our Website</h1>
                <nav>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </nav>
            </header>
            <main>
                <section className="hero">
                    <h2>Discover Amazing Products</h2>
                    <p>Find the best products and services tailored just for you.</p>
                    <a href="/products" className="btn">Explore Now</a>
                </section>
                <section className="featured">
                    <h2>Featured Products</h2>
                    <div className="product-list">
                        {/* Display featured products here */}
                        <div className="product">
                            <img src="product1.jpg" alt="Product 1" />
                            <h3>Product 1</h3>
                            <p>Description of Product 1</p>
                        </div>
                        <div className="product">
                            <img src="product2.jpg" alt="Product 2" />
                            <h3>Product 2</h3>
                            <p>Description of Product 2</p>
                        </div>
                        <div className="product">
                            <img src="product3.jpg" alt="Product 3" />
                            <h3>Product 3</h3>
                            <p>Description of Product 3</p>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <p>&copy; 2024 Our Website. All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default Home;
