import React, { useState } from 'react';
import api from '../constants/api';
import './../styles/style.css';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    const [user_email, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    //const [loading, setLoading] = useState('');
    const navigate = useNavigate();


    const handleLogin = async () => {
        let formData = new FormData();
        formData.append("useremail", user_email);
        formData.append("password", password);
        try {
            const response = await fetch(api.custom_url + "auth/signin.php", {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success === true) {
                    navigate('/Home');
                }
                else { setError(data.message); }

                //console.log(data);
            }
            else {
                // Handle error response
                const errorMessage = await response.text();
                console.error('Error:', errorMessage);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <div className="login-container">
            <h2>Login</h2>
            {error && <p className="error-message">{error}</p>}
            <input
                type="text"
                placeholder="Username"
                value={user_email}
                onChange={(e) => setUserEmail(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};

export default SignIn;
